import request from '@/utils/request'
const url = '/api/blog/'

export function category(params) {
  return request({
    url: url + 'category',
    method: 'get',
    params: params
  })
}
export function list(params) {
  return request({
    url: url + 'list',
    method: 'get',
    params: params
  })
}
export function info(params) {
  return request({
    url: url + 'info',
    method: 'get',
    params: params
  })
}
export function banner(params) {
  return request({
    url: url + 'banner',
    method: 'get',
    params: params
  })
}
export function categoryinfo(params) {
  return request({
    url: url + 'category_info',
    method: 'get',
    params: params
  })
}
export function category_list(params) {
  return request({
    url: url + 'category_list',
    method: 'get',
    params: params
  })
}