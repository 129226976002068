<template>
  <div class="container">
   
    <Header></Header>


    <div class="ny-main">
     
        <h3 class="ny-title text-center">BLOG</h3>
        <div class="blog-top">
          <!-- <h4>Premium contents to help you grow</h4>
          <p>Grow your Fashion , Lifestyle or Luxury brands with our actionable insights,platform advice and design inspiration.</p> -->
          <div class="blog-top-title">All Articles</div>
          <ul class="blog-top-list">
            <li v-for="(item,a) in model.category" :key="a">
              <router-link :to="{name:'blog-group',query:{'category':item.url_name}}">{{item.category_name}}</router-link>
			  <!--<router-link to="blog-group">{{item.category_name}}</router-link>-->
            </li>
          </ul>
        </div>
        <div class="ny-con">
          <div class="blog-m1">
            <el-row :gutter="60">
              <el-col :xs="14" :sm="14" :md="16" :lg="16">
                <div class="blog-m1-left">

                  <el-carousel  ref="carousel"  arrow="never" v-proportion="0.5455">
                    <el-carousel-item class="carousel-item" v-for="(item,index) in model.banner" :key="index">
                     <img class="carousel-img" :src="item.img_url" width="100%" v-proportion="0.5455">
                    </el-carousel-item>
                  </el-carousel>

                </div>
              </el-col>
              <el-col :xs="10" :sm="10" :md="8" :lg="8">
                <div class="blog-m1-right">
                  <h3 class="blog-title">Recommended News</h3>
                  <ul class="blog-m1-list">
                    <li v-for="(item,b) in model.banner" :key="b">
                      <p><router-link :to="{name:'blog-detail',query:{'id':item.blog_id}}">{{item.blog_name}}</router-link></p>
					  <div class="blog-m1-list-name">by STARMAX</div>
                    </li>
                  </ul>
                  
                </div>
              </el-col>
              
            </el-row>
          </div>
          <!-- blog-m1 end -->

          <div class="blog-m2">
              <div class="blog-m2-list">
                <el-row :gutter="30">
                  <el-col :span="8" v-for="(item,c) in model.latest" :key="c">
                    <div class="blog-m2-item wow animate__animated animate__fadeInUp"  data-wow-delay="0.2s"  data-wow-duration="0.5s">
                      <div class="blog-m2-pic" v-proportion="0.66">
                         <router-link :to="{name:'blog-detail',query:{'id':item.content_id}}"><img :src="item.img_url" alt="" v-proportion="0.66" /></router-link>
                      </div>
                      <h4><router-link :to="{name:'blog-detail',query:{'id':item.content_id}}">{{item.name}}</router-link></h4>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="blog-button-box text-center">
                <el-button class="blog-button-more" v-show="morenews" @click="moreBtn()">Loading More</el-button>
				<el-button class="blog-button-more" v-show="nomore">No More</el-button>
              </div>
          </div>
          <!-- blog-m2 end -->

          <div class="blog-m3">
            <h3 class="blog-title">Hot Topics</h3>

            <div class="blog-m3-list">

              <el-row :gutter="30">
                  <el-col :span="6" v-for="(item,d) in model.hotlist" :key="d">
                    <div class="blog-m3-item wow animate__animated animate__fadeInUp" data-wow-delay="0.2s"  data-wow-duration="0.5s">
                      <h4><router-link :to="{name:'blog-group',query:{'category':item.url_name}}">{{item.category_name}}</router-link></h4>
                      <div class="blog-m3-txt" v-for="(item2,dd) in item.list"><router-link :to="{name:'blog-detail',query:{'id':item2.content_id}}">{{item2.name}}</router-link></div>
                    </div>
                  </el-col>
                </el-row>

            </div>


          </div>
          <!-- blog-m3 end -->

          <div class="blog-m4" v-show="emailbox">
            <div class="blog-m4-top">
              <h3 class="blog-m4-title text-center">Discuss your project with us</h3>
              <div class="blog-m4-text text-center">Find out how Starmax makes the entire procurement process so easy.</div>
            </div>
			<div class="i-m11-input">
				<el-form ref="form" :model="form" class="cont-form">
					<el-row :gutter="40">
						<el-col>
							<el-form-item >
								<el-input v-model="form.name" placeholder="Enter your name"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="40">
						<el-col>
							<el-form-item >
								<el-input v-model="form.email" placeholder="Enter your email" ></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
            <div class="blog-m4-link">
                <el-button  @click="emailSubmit()">
                  <span class="icon-email"><img src="https://api.starmaxintl.com/storage/file/20220928/235042b5850e787757f9d4a9198854cf154ed0eb.png" alt=""></span>
                  Enter  Your  Email  <i>>></i>  
                </el-button>
            </div>
          </div><!--blog-m4 end -->
		  <div class="blog-m4" v-show="emailfreeback">
			<div class="i-m3-bottom">
				<h3 class="blog-m4-title text-center">Thank you for connecting!</h3>
				<div class="blog-m4-text text-center">You’re now subscribed to receive our newsletter every two weeks.<br/>Hope you'll enjoy it!</div>
			</div>
	
		  </div>

        </div>
     
    </div>
    <!-- main end  -->
     <Footer></Footer>
  </div>  
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { category, list, category_list, banner } from '@/api/blog'
import { add } from '@/api/email'


export default {
  components: {
    Header,
    Footer,
  
  },
  data() {
    return {
	 page: 1,
	 morenews :true,
	 nomore :false,
	 form: {},
	 emailbox: true,
	 emailfreeback: false,
     model: {
	   category: [],
	   banner: [],
	   reclist: [],
	   hotlist: [],
	   latest: [],
	 },
    };
  },
 
 mounted(){
  $(function(){
    let imgWidth = $('.blog-m1-left').width();
   
    //$('.carousel-img').height(imgWidth/1.833)
  })

  new this.$wow.WOW().init({
    boxClass: 'wow',
    animateClass: 'animate__animated',
    offset: 0,
    mobile: true,
    live: true

  });
 },
 created() {
    this.category()
	this.banner()
	this.hotlist()
	this.reclist()
	this.latest()
  },
  methods: {
    // 分类选择
    category() {
      category().then(res => {
        this.model.category = res.data.data
      }).catch(() => {})
    },
	banner() {
      banner().then(res => {
        this.model.banner = res.data.data.list
      }).catch(() => {})
    },
	hotlist() {
      category_list().then(res => {
        this.model.hotlist = res.data.data
      }).catch(() => {})
    },
	reclist() {
	  list({type:'rec'}).then(res => {
        this.model.reclist = res.data.data.list
      }).catch(() => {})
    },
	latest() {
      list().then(res => {
        this.model.latest = res.data.data.list
      }).catch(() => {})
    },
	//加载更多
	moreBtn() {
	  this.page+=1
	  list({page:this.page}).then(res => {
        this.model.latest = this.model.latest.concat(res.data.data.list)
		if(res.data.data.list.length==0){
		  this.morenews=false
		  this.nomore=true
		}
		//alert(JSON.stringify(res.data.data.list))
      }).catch(() => {})
	},
	emailSubmit(){
      add(this.form).then(res => {
	    if(res.data.code==200){
		   this.emailbox=false
		   this.emailfreeback=true
		   res.data.msg='Submitted successfully!</br>Our sales will contact you within 48 hours.'
		}
		else{
			this.$alert(res.data.msg, {
			  confirmButtonText: 'OK',
			  center:true,
			  dangerouslyUseHTMLString: true
			}).then(()=>{});
		}
	  	
	  }).catch(() => {})
	},
  },
}
</script>

<style scoped >


.ny-main {
  overflow: hidden;
  padding: 50px 0;
}

.ny-title {
  color: #0a3c5b;
  line-height: 1;
  font-size: 6.25vw;
  font-family: DINCound;
  font-weight: normal;
}

.blog-top{
  /* background: #fff; */
  padding: 50px 8.3vw;
  overflow: hidden;
  margin-top: 50px;
}

.blog-top h4{
  line-height: 1;
  color: #0a3c5b;
  font-size: 3.75vw;
  font-family: DINCound;
  font-weight: normal;
}

.blog-top p{
  line-height: 36px;
  color: #000;
  font-size: 18px;
  margin-top: 20px;
}

.blog-top-list{
  overflow: hidden;
  width: 100%;
  margin-top: 25px;
}

.blog-top-list li{
  width: 25%;
  float: left;
  height: 30px;
  font-size: 20px;
  color: #000;
  line-height: 30px;
  margin-top: 25px;
  cursor: pointer;
}

.blog-top-list li a{
  color: #000;
}

.blog-top-list li:hover{
  text-decoration: underline;
}

.ny-con {
  overflow: hidden;
  padding: 0 8.3vw
}

.blog-m3,
.blog-m1{
  margin-top: 80px;
}

.blog-m1-left{
  width: 100%;
  overflow: hidden;
  position: relative;
}


.blog-m1-left /deep/ .el-carousel__container{
    height: 100%;
}

.blog-m1-left /deep/ .el-carousel__button{
    height: 6px;
}

.blog-m1-left /deep/ .carousel-item {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
}
.blog-m1-left /deep/ .carousel-img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    display: block;
}
.blog-m1-left /deep/ .el-carousel__indicator--horizontal{
    margin: 0 6px;
}



.blog-m1-left img{
  width: 100%;
}

.blog-title {
  width: 200px;
  height: 48px;
  background: url("https://api.starmaxintl.com/storage/file/20221004/8832722f2447e5735c84ceaebe255637e97ee41a.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 0 20px;
  line-height: 48px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  font-weight: normal;
}

.blog-m1-list{
  overflow: hidden;
}

.blog-m1-list li{
  margin-top: 8%;
}

.blog-m1-list li p{
 white-space:nowrap;
    text-overflow:ellipsis;
    word-break:break-all;
    overflow:hidden;
  font-size: 1.25vw;
}

.blog-m1-list-name{
  font-size: 0.9325vw;
  
}
.blog-m1-list li a,
.blog-m1-list-name{
  line-height: 1.5;
  color: #000;
}

.blog-m2{
  margin-top: 100px;
}

.blog-m2-pic,
.blog-m2-item,
.blog-m2-item img{
  width: 100%;
  transition: all 1s;
}

.blog-m2-pic{
  overflow: hidden;
  cursor: pointer;
}

.blog-m2-item:hover .blog-m2-pic img{
  transform: scale(1.2);
}

.blog-m2-item{
  text-align: center;
  margin-bottom: 60px;
}

.blog-m2-item h4{
  line-height: 40px;
  height: 40px;
  overflow: hidden;
  color: #0a3c5b;
  font-size: 1.6667vw;
  font-family: DINCound;
  font-weight: normal;
  margin: 10px 0 0;
}
.blog-m2-item h4 a{color: #0a3c5b;}
.blog-m2-item P{
  color: #000;
  font-size: 0.9325vw;
  line-height: 1.5;
}

.blog-button-more{
  width: 300px;
  height: 60px;
  line-height: 60px;
  padding: 0px 20px;
  font-size: 20px;
  color: #0a3c5b;
  font-family: "ROMANTIC";
  border-color: #0a3c5b;
}

.blog-m3-list{
  margin-top: 30px;
}

.blog-m3-item{
  width: 100%;
  margin-bottom: 50px;
}

.blog-m3-item h4{
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  color: #0a3c5b;
  font-size: 1.6667vw;
  border-bottom: 1px solid #dcdde1;
  font-family: DINCound;
  font-weight: normal;
}
.blog-m3-item h4 a{color: #0a3c5b;}

.blog-m3-txt{
  margin-top: 25px;
  width: 100%;
  white-space:nowrap;
    text-overflow:ellipsis;
    word-break:break-all;
    overflow:hidden;
}

.blog-m3-txt a{
  line-height: 36px;
  height: 36px;
  overflow: hidden;
  color: #000;
  font-size: 18px;
}

.blog-m4{
   margin: 30px 0;
}

.blog-m4-title{
    color: #0a3c5b;
    line-height: 1;
    font-size: 3.75vw;
    font-family: DINCound;
    font-weight: normal;
}

.blog-m4-text{
  line-height: 36px;
  font-size: 18px;
  color: #000000;
  margin-top: 30px;
}

.blog-m4-link{
    text-align: center;
  
}

.blog-m4-link .el-button{
    color: #0a3c5b;
    font-size: 20px;
    border-color: #166596;
    line-height: 28px;
    font-family: DINCound;
}

.blog-m4-link .el-button i{
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 5px;
    font-size: 16px;
}

.icon-email{
    display: inline-block;
    height: 28px;
    width: 28px;
    position: relative;
}

.icon-email img{
    width: 100%;
    height: 100%;
    vertical-align: top;
}

.blog-m4-link .el-button .icon-email{
    margin-right: 5px;
}

.blog-top{
  background: #fbfcf7;
}

.blog-top-title{
  height: 54px;
  width: 233px;
  background: url("https://api.starmaxintl.com/storage/file/20221004/ef1d9d477f8a4229b409c8c2e52be3d4e313d6b4.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 0 63px 0 15px;
  line-height: 48px;
  color: #fff;
  font-size: 24px;
}

.i-m11-input{width:25%; margin:30px auto 0 auto;}
</style>
